import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

interface Props {
  text: string;
}

export default function WhatsAppButton({ text }: Props) {
  const whatsAppUrl = `whatsapp://send?text=${encodeURIComponent(text)}`;
  const whatsAppTitle = 'Bei WhatsApp teilen';
  const whatsAppText = 'teilen';

  return (
    <a
      role="button"
      href={whatsAppUrl}
      title={whatsAppTitle}
      aria-label={whatsAppTitle}
      rel="nofollow"
      className="button is-whatsapp"
    >
      <span className="icon">
        <FontAwesomeIcon icon={faWhatsapp} />
      </span>
      <span>{whatsAppText}</span>
    </a>
  );
}
