import logo from '../img/logo_full.png';

export const languageCode = 'de';
export const countryCode = 'DE';

export const schemaAuthor = {
  '@type': 'Person',
  familyName: 'Kiene',
  name: 'Udo',
  email: 'kiene-800@gmx.de',
};

export const schemaCreator = {
  '@type': 'Person',
  familyName: 'Damerow',
  name: 'Stefan',
  email: 'mail@stefan-damerow.de',
};

export const schemaSourceOrganization = {
  '@type': 'MusicGroup',
  name: 'Die aus dem Eschenbachtal',
  url: 'https://www.die-aus-dem-eschenbachtal.de',
  foundingDate: '2014',
  logo: {
    '@type': 'ImageObject',
    contentUrl: logo,
    width: 512,
    height: 512,
  },
  parentOrganization: {
    '@type': 'MusicGroup',
    name: 'Musikverein Ober-/Untereschenbach e.V.',
    alternateName: 'Eschenbacher Musikanten',
    url: 'http://www.eschenbacher-musikanten.de',
  },
};

export const schemaWebSite = {
  '@type': 'WebSite',
  name: 'Die aus dem Eschenbachtal',
  description:
    'Dies ist die Webseite der Alphornbläsergruppe aus dem Eschenbachtal bei Hammelburg.',
  inLanguage: `${languageCode}-${countryCode}`,
};
