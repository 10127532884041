import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

interface Props {
  url: string;
  text: string;
}

function abbreviateText(text: string, length: number): string {
  if (text.length <= length) {
    return text;
  }
  const lastWhitespaceIndex = text.substring(0, length - 1).lastIndexOf(' ');
  return `${text.substring(0, lastWhitespaceIndex)}\u2026`;
}

export default function TwitterButton({ url, text }: Props) {
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    abbreviateText(text, 120),
  )}&url=${encodeURIComponent(url)}`;
  const twitterTitle = 'Bei Twitter teilen';
  const twitterText = 'tweet';

  return (
    <a
      href={twitterUrl}
      role="button"
      title={twitterTitle}
      aria-label={twitterTitle}
      rel="nofollow"
      className="button is-twitter"
      onClick={event => {
        event.preventDefault();
        if (
          /* eslint-disable no-underscore-dangle */
          window.__twttr &&
          window.__twttr.widgets &&
          window.__twttr.widgets.loaded
          /* eslint-enable no-underscore-dangle */
        ) {
          return;
        }
        window.open(twitterUrl, '_blank', 'width=600,height=460');
      }}
    >
      <span className="icon">
        <FontAwesomeIcon icon={faTwitter} />
      </span>
      <span>{twitterText}</span>
    </a>
  );
}
