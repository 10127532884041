import React from 'react';

interface Props {
  content: string;
  className?: string;
}

export function HTMLContent({ content, className }: Props) {
  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
  );
}

export default function Content({ content, className }: Props) {
  return <div className={className}>{content}</div>;
}
