import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

interface Props {
  url: string;
}

export default function FacebookButton({ url }: Props) {
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    url,
  )}`;
  const facebookTitle = 'Bei Facebook teilen';
  const facebookText = 'teilen';

  return (
    <a
      role="button"
      href={facebookUrl}
      title={facebookTitle}
      aria-label={facebookTitle}
      rel="nofollow"
      className="button is-facebook"
      onClick={event => {
        event.preventDefault();
        window.open(facebookUrl, '_blank', 'width=600,height=460');
      }}
    >
      <span className="icon">
        <FontAwesomeIcon icon={faFacebookSquare} />
      </span>
      <span>{facebookText}</span>
    </a>
  );
}
