export function getMimeTypeFromExtension(filename: string): string | undefined {
  const matches = filename
    .toLowerCase()
    .match(/\.(gif|jpeg|jpg|jpe|png|svg|webp)$/);

  if (matches) {
    switch (matches[0]) {
      case 'gif':
        return 'image/gif';
      case 'jpeg':
        return 'image/jpeg';
      case 'jpg':
        return 'image/jpeg';
      case 'jpe':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'svg':
        return 'image/svg+xml';
      case 'webp':
        return 'image/webp';
      default:
        return undefined;
    }
  }

  return undefined;
}

interface GetDateOptions {
  day?: boolean;
  month?: boolean;
  year?: boolean;
  locales?: string | string[];
}

export function getDate(
  date: Date,
  { day = true, month = true, year = true, locales }: GetDateOptions = {},
): string {
  return date.toLocaleDateString(locales, {
    day: day ? 'numeric' : undefined,
    month: month ? 'long' : undefined,
    year: year ? 'numeric' : undefined,
  });
}
