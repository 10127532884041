import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import {
  faAngleUp,
  faEnvelope,
  faMobile,
} from '@fortawesome/free-solid-svg-icons';

import { schemaWebSite } from '../constants';
import logo from '../img/logo_full.svg';

export default function Footer() {
  return (
    <footer
      className="footer has-background-black has-text-white-ter"
      style={{ position: 'relative', marginTop: '2rem' }}
    >
      <button
        type="button"
        className="scroll-button"
        onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </button>
      <div className="container">
        <div className="columns">
          <div className="column is-4">
            <section>
              <figure className="image has-text-centered-mobile">
                <img
                  className="is-rounded"
                  src={logo}
                  alt={schemaWebSite.name}
                  style={{
                    backgroundColor: '#fff',
                    padding: '8px',
                    width: '256px',
                    height: '256px',
                  }}
                />
              </figure>
            </section>
          </div>
          <div className="column is-4">
            <h4 className="is-size-4 has-text-centered-mobile">Links</h4>
            <hr />
            <section>
              <ul className="menu-list has-text-centered-mobile">
                <li>
                  <Link to="/">Startseite</Link>
                </li>
                <li>
                  <Link to="/galerien">Galerien</Link>
                </li>
                <li>
                  <Link to="/termine">Termine</Link>
                </li>
                <li>
                  <Link to="/datenschutz">Datenschutz</Link>
                </li>
              </ul>
            </section>
          </div>
          <div className="column is-4">
            <h4 className="is-size-4 has-text-centered-mobile">Udo Kiene</h4>
            <hr />
            <section>
              <ul className="fa-ul">
                <li>
                  <span className="fa-li" aria-hidden="true">
                    <FontAwesomeIcon icon={faMobile} />
                  </span>
                  <a href="tel:+49-173-9956626" rel="nofollow">
                    +49 (0) 173 9956626
                  </a>
                </li>
                <li>
                  <span className="fa-li" aria-hidden="true">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <a href="mailto:kiene-800@gmx.de" rel="nofollow">
                    kiene-800@gmx.de
                  </a>
                </li>
                <li>
                  <span className="fa-li" aria-hidden="true">
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </span>
                  <a
                    title="Facebook"
                    href="https://www.facebook.com/udo.kiene.5"
                    rel="nofollow"
                  >
                    Facebook
                  </a>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </footer>
  );
}
