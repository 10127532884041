import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { merge } from 'lodash';

import Navbar from './Navbar';
import Footer from './Footer';
import SocialButtons from './SocialButtons';
import logo from '../img/logo_full.png';

import '../styles/main.scss';

interface Props {
  children?: React.ReactNode;
  location?: string;
  title?: string;
  schema?: object;
}

export default function Layout({ children, location, schema, title }: Props) {
  const siteMetadata = {
    name: 'Die aus dem Eschenbachtal',
    description:
      'Dies ist die Webseite der Alphornbläsergruppe aus dem Eschenbachtal bei Hammelburg.',
    languageCode: 'de',
    countryCode: 'DE',
  };

  return (
    <React.StrictMode>
      <HelmetProvider>
        <Helmet
          titleTemplate={`%s | ${siteMetadata.name}`}
          defaultTitle={siteMetadata.name}
        >
          <html
            lang={siteMetadata.languageCode}
            prefix={[
              'og: http://ogp.me/ns#',
              'fb: http://ogp.me/ns/fb#',
              'website: http://ogp.me/ns/website#',
            ].join(' ')}
          />
          <meta name="description" content={siteMetadata.description} />
          <meta
            property="og:locale"
            content={`${siteMetadata.languageCode}_${siteMetadata.countryCode}`}
          />
          <meta property="og:site_name" content={siteMetadata.name} />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
            {JSON.stringify(
              merge(
                {
                  '@context': 'http://schema.org',
                  '@type': 'WebPage',
                  ...(location && { url: location }),
                  isPartOf: {
                    '@type': 'WebSite',
                    name: siteMetadata.name,
                    description: siteMetadata.description,
                    inLanguage: `${siteMetadata.languageCode}-${siteMetadata.countryCode}`,
                    author: {
                      '@type': 'Person',
                      familyName: 'Kiene',
                      name: 'Udo',
                      email: 'kiene-800@gmx.de',
                    },
                    publisher: {
                      '@type': 'Person',
                      familyName: 'Kiene',
                      name: 'Udo',
                      email: 'kiene-800@gmx.de',
                    },
                    creator: {
                      '@type': 'Person',
                      familyName: 'Damerow',
                      name: 'Stefan',
                      email: 'mail@stefan-damerow.de',
                    },
                    sourceOrganization: {
                      '@type': 'MusicGroup',
                      name: 'Die aus dem Eschenbachtal',
                      url: 'https://www.die-aus-dem-eschenbachtal.de',
                      foundingDate: '2014',
                      logo: {
                        '@type': 'ImageObject',
                        contentUrl: logo,
                        width: 512,
                        height: 512,
                      },
                      parentOrganization: {
                        '@type': 'MusicGroup',
                        name: 'Musikverein Ober-/Untereschenbach e.V.',
                        alternateName: 'Eschenbacher Musikanten',
                        url: 'http://www.eschenbacher-musikanten.de',
                      },
                    },
                  },
                },
                schema,
              ),
            )}
          </script>
        </Helmet>
        <Navbar />
        {children}
        {location && (
          <div className="container" style={{ marginBottom: '1.5rem' }}>
            <SocialButtons
              url={location}
              title={
                title ? `${title} | ${siteMetadata.name}` : siteMetadata.name
              }
            />
          </div>
        )}
        <Footer />
      </HelmetProvider>
    </React.StrictMode>
  );
}
