import React from 'react';

import FacebookButton from './FacebookButton';
import TwitterButton from './TwitterButton';
import WhatsAppButton from './WhatsAppButton';

interface Props {
  url: string;
  title: string;
}

export default function SocialButtons({ url, title }: Props) {
  return (
    <div className="buttons is-centered">
      <FacebookButton url={url} />
      <TwitterButton url={url} text={title} />
      <WhatsAppButton text={title} />
    </div>
  );
}
