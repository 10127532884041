import React from 'react';
import { Link } from 'gatsby';
import { v4 } from 'uuid';

import { schemaWebSite } from '../constants';

import icon from '../img/icon.svg';
import logo from '../img/logo.svg';

const urlPrefix = 'https://www.die-aus-dem-eschenbachtal.de';
const items = [
  {
    to: '/',
    url: `${urlPrefix}/`,
    name: 'Startseite',
  },
  {
    to: '/galerien',
    url: `${urlPrefix}/galerien`,
    name: 'Galerien',
  },
  {
    to: '/termine',
    url: `${urlPrefix}/termine`,
    name: 'Termine',
  },
  {
    to: '/datenschutz',
    url: `${urlPrefix}/datenschutz`,
    name: 'Datenschutz',
  },
];

export default class Navbar extends React.PureComponent<{}> {
  private burgerRef: React.RefObject<HTMLButtonElement>;

  private menuRef: React.RefObject<HTMLDivElement>;

  public constructor(props: {}) {
    super(props);

    this.burgerRef = React.createRef();
    this.menuRef = React.createRef();

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  private toggleMenu(event: React.MouseEvent<HTMLButtonElement>) {
    const menu = this.menuRef.current;
    const burger = this.burgerRef.current;

    event.preventDefault();

    if (menu && burger) {
      menu.classList.toggle('is-active');
      burger.classList.toggle('is-active');
      burger.setAttribute(
        'aria-expanded',
        `${menu.classList.contains('is-active')}`,
      );
    }
  }

  public render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="Hauptmenü"
        itemScope
        itemType="http://schema.org/SiteNavigationElement"
      >
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <img src={icon} alt={schemaWebSite.name} height="28" />
            <img
              src={logo}
              alt={schemaWebSite.name}
              height="28"
              style={{ marginLeft: '0.5rem' }}
            />
          </Link>
          <button
            type="button"
            className="navbar-burger burger"
            onClick={this.toggleMenu}
            ref={this.burgerRef}
            aria-label="Menü"
            aria-expanded="false"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </button>
        </div>
        <div className="navbar-menu" ref={this.menuRef}>
          <div className="navbar-end has-text-centered">
            {items.map(item => (
              <Link
                key={v4()}
                className="navbar-item"
                activeClassName="is-active"
                to={item.to}
              >
                <meta itemProp="url" content={item.url} />
                <span itemProp="name">{item.name}</span>
              </Link>
            ))}
          </div>
        </div>
      </nav>
    );
  }
}
